<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW BRANCH</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              @change="selected_category"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-radio-group
              v-model="row"
              row
              dense
            >
              <v-radio
                label="Is Regional?"
                value="radio-1"
                @change="selected_category"
              ></v-radio>
              <v-radio
                label="Is Territory?"
                value="radio-2"
                @change="selected_category"
              ></v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              v-model="region_id"
              class="mx-2"
              dense
              outlined
              label="Region"
              :items="region_items"
              item-value="id"
              item-text="region_no"
              @change="selected_region"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              v-model="province_id"
              class="mx-2"
              dense
              outlined
              label="Province"
              :items="province_items"
              item-value="id"
              item-text="province"
              @change="selected_province"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              v-model="city_id"
              class="mx-2"
              dense
              outlined
              label="City/Municipality/Brgy."
              :items="city_items"
              item-value="id"
              item-text="city"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="branch_code"
              label="Branch Code"
              dense
              outlined
              :rules="rules.default_max_75_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="address"
              label="Address"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="contact_no"
              label="Contact #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="email_address"
              label="Email Address"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="tin_no"
              label="TIN #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Request
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Branch Code
                </th>
                <th class="text-uppercase">
                  Address
                </th>
                <th class="text-uppercase">
                  Contact #
                </th>
                <th class="text-uppercase">
                  Email Address
                </th>
                <th class="text-uppercase">
                  TIN
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in active_items"
                :key="item.id"
              >
                <td>
                  {{ (active_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.branch_code }}
                </td>
                <td>
                  {{ item.address }}
                </td>
                <td>
                  {{ item.contact_no }}
                </td>
                <td>
                  {{ item.email_address }}
                </td>
                <td>
                  {{ item.tin_no }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Branch Code
                </th>
                <th class="text-uppercase">
                  Address
                </th>
                <th class="text-uppercase">
                  Contact #
                </th>
                <th class="text-uppercase">
                  Email Address
                </th>
                <th class="text-uppercase">
                  TIN
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in deactive_items"
                :key="item.id"
              >
                <td>
                  {{ (deactive_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.branch_code }}
                </td>
                <td>
                  {{ item.address }}
                </td>
                <td>
                  {{ item.contact_no }}
                </td>
                <td>
                  {{ item.email_address }}
                </td>
                <td>
                  {{ item.tin_no }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      category_id: '',
      category_items: [],
      active_items: [],
      deactive_items: [],
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiCheck},
        {title: 'DEACTIVATED', icon: mdiClose},
      ],
      tin_no: '',
      email_address: '',
      contact_no: '',
      address: '',
      branch_code: '',
      city_id: '',
      city_items: [],
      province_id: '',
      province_items: [],
      region_id: '',
      region_items: [],
      row: null,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['initialize_branch', 'branch_data', 'register_new_branch']),
      initialize_data() {
        this.initialize_branch()
          .then(response => {
            this.category_items = response.data[0].category
            this.region_items = response.data[0].region
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_region() {
        if (this.region_items.length > 0) {
          var index = this.region_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.region_id)
          if (index >= 0) {
            this.province_items = this.region_items[index].province
            this.city_items = []
          }
        }
      },
      selected_province() {
        if (this.province_items.length > 0) {
          var index = this.province_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.province_id)
          if (index >= 0) {
            this.city_items = this.province_items[index].city
          }
        }
      },
      selected_category() {
        const data = new FormData()
        data.append('category_id', this.category_id);
        data.append('is_region', this.row === 'radio-2' ? 1 : 0);
        data.append('is_territory', this.row === 'radio-1' ? 1 : 0);
        this.branch_data(data)
          .then(response => {
            this.active_items = response.data[0].active
            this.deactive_items = response.data[0].deactivate
          })
          .catch(error => {
            console.log(error)
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id);
          data.append('branch_code', this.branch_code.toUpperCase());
          data.append('address', this.address.toUpperCase());
          data.append('contact_no', this.contact_no);
          data.append('email_address', this.email_address);
          data.append('tin_no', this.tin_no);
          data.append('region_id', this.region_id);
          data.append('province_id', this.province_id);
          data.append('branch_city_id', this.city_id);
          data.append('is_region', this.row === 'radio-1');
          data.append('is_territory', this.row === 'radio-2');
          this.register_new_branch(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
              this.category_id = cat
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
